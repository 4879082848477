<template>
  <NuxtLink v-if="to"
    :to="to"
    :active-class="activeClass"
    :append="append"
    :event="event"
    :exact="exact"
    :exact-active-class="exactActiveClass"
    :replace="replace"
    :prefetch="prefetch"
    :title="title"
    :rel="rel"
    :target="target"
    @click="handleClickEvent"
  >
    <slot></slot>
  </NuxtLink>
  <a v-else
    :href="href"
    :title="title"
    :rel="rel"
    :target="target"
    @click="handleClickEvent"
  >
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  to? :string,
  href? : string
  title? : string
  target? : '_self'|'_blank'
  rel? : string

  // <router-link> props
  activeClass? : string
  append? : boolean
  /**
   * click
   */
  event? : string|[]
  exact? : boolean
  exactActiveClass? : string
  replace? : boolean

  // <nuxt-link> props
  prefetch? : boolean
}>(),{
  target: () => '_self',
  append: () => false,
  event: () => 'click',
  exact: () => false,
  replace: () => false,
  prefetch: () => false
})

const emit = defineEmits<{
  (e: 'click', v: Event): void
}>()

const route = useRoute()

const handleClickEvent = (event: Event) => {
  if(props.to === route.fullPath) {
    event.preventDefault()
  }

  if (props.href === '#') {
    event.preventDefault()
  }

  emit('click', event)
}

</script>